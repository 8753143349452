import React, { useEffect, useRef, useState } from 'react';
import ReactSelect, { components, InputActionMeta } from 'react-select';

import { Form, Formik, Field, ErrorMessage, FormikHelpers, FormikProps } from 'formik';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';
import { useMutation } from 'react-query';
import { navigate } from 'gatsby';

import {
    container,
    title,
    question,
    noMargin,
    optional,
    hint,
    info,
    textarea,
    input,
    questionBox,
    hiddenInputs,
    hiddenInfo,
    infoItem,
    visible,
    counter,
    fields,
    checkboxes,
    checkboxContainer,
    centered,
    checkbox,
    checkboxLabel,
    checkmark,
    button,
    error,
    loading,
    buttonRadio,
    wide,
    big,
    divider,
    buttons,
    selected,
    fileContainer,
    fileInput,
    phoneInput,
    select,
    shopSelect,
    position,
    codeInput,
    codeInfo,
    codeError,
} from './akcja-gorzka-form.module.scss';

import { IShop } from '../../models/shop.model';
import staticFiles from '../../config/static-files';
import pagesContext from '../../config/pages-context';
import {
    getValidationSchema,
    initialValues,
    IAkcjaGorzkaValues,
    AKCJA_GORZKA_MAX_ANSWER_LENGTH,
} from '../../formik/akcja-gorzka-form.config';
import {
    akcjaGorzkaFormMutation,
    getAkcjaGorzkaCities,
    getAkcjaGorzkaShopByNumber,
    getAkcjaGorzkaShops,
} from '../../api-ssr/akcja-gorzka';
import { useModal } from '../../aes/hooks/use-modal';

import FormCheckbox from '../atoms/form-checkbox';
import Button from '../atoms/button';
import FileInput from '../atoms/file-input';
import FormField from '../atoms/form-field';
import HandleFormikChange from '../hoc/handle-formik-change';
import Markdown from '../hoc/markdown';

interface IAkcjaGorzkaFormProps {
    className?: string;
}

interface IShopData {
    cityId: number;
    cityName: string;
    houseNumber: string;
    name: string;
    shopId: number;
    street: string;
    zipCode: string;
}

const MAX_FILE_SIZE = 26214400;

const AkcjaGorzkaForm: React.FC<IAkcjaGorzkaFormProps> = ({ className = '' }) => {
    const { t, language } = useI18next();
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: submitForm } = useMutation(akcjaGorzkaFormMutation);
    const { mutateAsync: getCities } = useMutation(getAkcjaGorzkaCities);
    const { mutateAsync: getShops } = useMutation(getAkcjaGorzkaShops);
    const { mutateAsync: getShopByNumber } = useMutation(getAkcjaGorzkaShopByNumber);
    const formRef = useRef<FormikProps<IAkcjaGorzkaValues>>(null);
    const cityRef = useRef<any>(null);
    const shopRef = useRef<any>(null);
    const shopDataRef = useRef<IShopData | null>(null);
    const selectedCityRef = useRef<number | null>(null);
    const selectedShopRef = useRef<number | null>(null);
    const [shopNumber, setShopNumber] = useState<number | null>();
    const [cities, setCities] = useState<{ cityId: number; name: string }[]>([]);
    const [shops, setShops] = useState<IShop[]>([]);
    const [shopByNumberError, setShopByNumberError] = useState(false);
    const { addModal } = useModal();

    const handleSubmit = async (
        values: IAkcjaGorzkaValues,
        formikHelpers: FormikProps<IAkcjaGorzkaValues>
    ) => {
        const { handleSubmit, isValid } = formikHelpers;

        handleSubmit();

        if (!isValid) {
            showModal();
        } else {
            setIsLoading(true);
            try {
                await submitForm(values);
                const resultsUrl = getPathFromSlug(pagesContext.akcjaGorzkaThankYou.slug, language);
                navigate(resultsUrl);
            } catch (err: any) {
                const error = { ...err }.response;
                if (
                    'status' in error &&
                    typeof error.status === 'number' &&
                    error.data &&
                    typeof error.data === 'object' &&
                    'messages' in error.data &&
                    Array.isArray(error.data.messages)
                ) {
                    alert(error.data.messages[0].content);
                } else {
                    alert(error);
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleAnswerTriedClick = (value: boolean, helpers: FormikHelpers<IAkcjaGorzkaValues>) => {
        helpers.setFieldValue('tried', value);
    };

    const handlePositionClick = (value: string, helpers: FormikHelpers<IAkcjaGorzkaValues>) => {
        helpers.setFieldValue('position', value);
    };

    const handleFormikChange = (values: IAkcjaGorzkaValues) => {
        if (values.phone.toString().length > 9) {
            formRef.current?.setFieldValue('phone', values.phone.toString().slice(0, 9));
        }

        if (values.cityId && values.cityId !== selectedCityRef.current) {
            selectedCityRef.current = values.cityId;
        }

        if (values.shopId && values.shopId !== selectedShopRef.current) {
            const pickedShop = shops.find((shop) => shop.shopId === values.shopId);

            if (pickedShop) {
                formRef.current?.setFieldValue('zipCode', pickedShop.zipCode);
                formRef.current?.setFieldValue('street', pickedShop.street);
                formRef.current?.setFieldValue('apartmentNumber', pickedShop.houseNumber);
                selectedShopRef.current = values.shopId;
            }
        }

        if (values.code !== shopNumber) {
            getShopByNumber(values.code ? values.code.toString() : '')
                .then((response: any) => {
                    if (response && response.data) {
                        shopDataRef.current = response.data;
                        setShopByNumberError(false);
                    }
                })
                .catch(() => {
                    shopDataRef.current = null;

                    if (values.code?.toString().length === 0) {
                        setShopByNumberError(false);
                    } else {
                        setShopByNumberError(true);
                    }
                });
            if (values.code) {
                setShopNumber(values.code);
            }
        }
    };

    const popuplateCitiesOptions = (value: string, _: InputActionMeta) => {
        if (value.length > 1) {
            try {
                getCities(value).then((response) => {
                    setCities(response.data);
                });
            } catch {}
        }
    };

    const populateShopsOptions = (value: string) => {
        if (value.length > 1 && selectedCityRef.current) {
            try {
                getShops(`${selectedCityRef.current}&query=${value}`).then((response) => {
                    setShops(response.data);
                });
            } catch {}
        }
    };

    const showModal = () => {
        addModal({
            modalKey: 'form-modal',
            modalProps: {
                actions: ['close', 'confirm'],
                isOpen: true,
                children: <div>{t('form.missing.fields')}</div>,
            },
        });
    };

    useEffect(() => {
        if (shopDataRef.current) {
            getCities(shopDataRef.current.cityName).then((response) => {
                setCities(response.data);
                cityRef.current?.selectOption({
                    label: shopDataRef.current?.cityName,
                    value: shopDataRef.current?.cityId,
                });
            });
            getShops(`${shopDataRef.current.cityId}&query=${shopDataRef.current.name}`).then(
                (response) => {
                    setShops(response.data);
                    shopRef.current?.selectOption({
                        label: shopDataRef.current?.name,
                        value: shopDataRef.current?.shopId,
                    });
                }
            );
            formRef.current?.setFieldValue('zipCode', shopDataRef.current.zipCode);
            formRef.current?.setFieldValue('street', shopDataRef.current.street);
            formRef.current?.setFieldValue('apartmentNumber', shopDataRef.current.houseNumber);
        } else {
            cityRef.current?.clearValue();
            shopRef.current?.clearValue();
            formRef.current?.setFieldValue('cityId', null);
            formRef.current?.setFieldValue('shopId', null);
            formRef.current?.setFieldValue('zipCode', '');
            formRef.current?.setFieldValue('street', '');
            formRef.current?.setFieldValue('apartmentNumber', '');
            selectedCityRef.current = null;
            selectedShopRef.current = null;
        }
    }, [shopDataRef.current]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            innerRef={formRef}
            validationSchema={getValidationSchema(t)}
        >
            {(helpers) => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    <HandleFormikChange onChange={handleFormikChange} />
                    <div className={questionBox}>
                        <h1 className={title}>
                            {t('akcja.gorzka.form.title')} <br /> {t('akcja.gorzka.form.subtitle')}
                        </h1>
                        <p className={question}>{t('akcja.gorzka.form.tried.1')}</p>
                        <div>
                            <div className={buttons}>
                                <button
                                    type="button"
                                    className={`${buttonRadio} ${big} ${
                                        helpers.values.tried ? selected : ''
                                    }`}
                                    onClick={() => {
                                        handleAnswerTriedClick(true, helpers);
                                    }}
                                >
                                    {t('akcja.gorzka.form.tried.answer1')}
                                </button>
                                <button
                                    type="button"
                                    className={`${buttonRadio} ${big} ${
                                        !helpers.values.tried && helpers.values.tried !== undefined
                                            ? selected
                                            : ''
                                    }`}
                                    onClick={() => {
                                        handleAnswerTriedClick(false, helpers);
                                    }}
                                >
                                    {t('akcja.gorzka.form.tried.answer2')}
                                </button>
                            </div>
                            <ErrorMessage name="tried" className={error} component="p" />
                        </div>
                        <div className={divider} />
                        <div className={`${hiddenInputs} ${helpers.values.tried ? visible : ''}`}>
                            <p className={question}>{t('akcja.gorzka.form.kind')}</p>
                            <div className={`${checkboxContainer} ${centered}`}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="kind1"
                                    text={t('akcja.gorzka.form.kind.1')}
                                />
                                <ErrorMessage name="kind1" className={error} component="p" />
                            </div>
                            <div className={`${checkboxContainer} ${centered}`}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="kind2"
                                    text={t('akcja.gorzka.form.kind.2')}
                                />
                                <ErrorMessage name="kind2" className={error} component="p" />
                            </div>
                            <div className={divider} />
                            <p className={`${question} ${noMargin}`}>
                                {t('akcja.gorzka.form.tried.2')}
                            </p>
                            <p className={info}>{t('akcja.gorzka.form.tried.2.info')}</p>
                            <div className={checkboxContainer}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="triedOption1"
                                    text={t('akcja.gorzka.form.tried.option1')}
                                />
                                <ErrorMessage name="triedOption1" className={error} component="p" />
                            </div>
                            <div className={checkboxContainer}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="triedOption2"
                                    text={t('akcja.gorzka.form.tried.option2')}
                                />
                                <ErrorMessage name="triedOption2" className={error} component="p" />
                            </div>
                            <div className={checkboxContainer}>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="triedOption3"
                                    text={t('akcja.gorzka.form.tried.option3')}
                                />
                                <ErrorMessage name="triedOption3" className={error} component="p" />
                            </div>
                            <Field
                                as="textarea"
                                type="textarea"
                                rows={5}
                                className={`${textarea} ${input}`}
                                name="triedAnswer"
                                placeholder={t('akcja.gorzka.form.tried.placeholder')}
                                maxLength={AKCJA_GORZKA_MAX_ANSWER_LENGTH}
                            />
                            <p className={counter}>
                                {helpers.values.triedAnswer.length}/{AKCJA_GORZKA_MAX_ANSWER_LENGTH}
                            </p>
                            <ErrorMessage name="triedAnswer" className={error} component="p" />
                            <div className={divider} />
                        </div>
                        <div
                            className={`${hiddenInfo} ${
                                !helpers.values.tried && helpers.values.tried !== undefined
                                    ? visible
                                    : ''
                            }`}
                        >
                            <p className={question}>{t('akcja.gorzka.form.tried.info.title')}</p>
                            <p className={infoItem}>{t('akcja.gorzka.form.tried.info.item1')}</p>
                            <p className={infoItem}>{t('akcja.gorzka.form.tried.info.item2')}</p>
                            <p className={infoItem}>{t('akcja.gorzka.form.tried.info.item3')}</p>
                            <div className={divider} />
                        </div>
                    </div>
                    <div className={questionBox}>
                        <p className={question}>{t('akcja.gorzka.form.answer')}</p>
                        <Field
                            as="textarea"
                            type="textarea"
                            rows={5}
                            className={`${textarea} ${input}`}
                            name="answer"
                            placeholder={t('akcja.gorzka.form.textarea.placeholder')}
                            maxLength={AKCJA_GORZKA_MAX_ANSWER_LENGTH}
                        />
                        <p className={counter}>
                            {helpers.values.answer.length}/{AKCJA_GORZKA_MAX_ANSWER_LENGTH}
                        </p>
                        <ErrorMessage name="answer" className={error} component="p" />
                    </div>
                    <div className={`${questionBox} ${optional}`}>
                        <p className={question}>{t('akcja.gorzka.form.files')}</p>
                        <p className={hint}>{t('akcja.gorzka.form.files.hint')}</p>
                        <FormField
                            id={'file-field'}
                            errorStyle={!!(helpers.errors.files && helpers.touched.files)}
                            hintText={
                                helpers.errors.files && helpers.touched.files
                                    ? (helpers.errors.files as string)
                                    : ''
                            }
                            className={fileContainer}
                        >
                            <FileInput
                                maxFileSize={MAX_FILE_SIZE}
                                fileTypes={[
                                    'application/pdf',
                                    'image/jpg',
                                    'image/jpeg',
                                    'image/png',
                                    'video/x-msvideo',
                                    'video/mov',
                                    'video/mp4',
                                ]}
                                className={fileInput}
                                name={'files'}
                                placeholder={t('akcja.gorzka.form.files.placeholder')}
                                type={'short'}
                            />
                        </FormField>
                        <ErrorMessage name="files" className={error} component="p" />
                    </div>
                    <div className={position}>
                        <div className={`${buttons} ${wide}`}>
                            <button
                                className={`${buttonRadio} ${wide} ${
                                    helpers.values.position === 'owner' ? selected : ''
                                }`}
                                type="button"
                                onClick={() => {
                                    handlePositionClick('owner', helpers);
                                }}
                            >
                                {t('akcja.gorzka.form.position.1')}
                            </button>
                            <button
                                className={`${buttonRadio} ${wide} ${
                                    helpers.values.position === 'manager' ? selected : ''
                                }`}
                                type="button"
                                onClick={() => {
                                    handlePositionClick('manager', helpers);
                                }}
                            >
                                {t('akcja.gorzka.form.position.2')}
                            </button>
                            <button
                                className={`${buttonRadio} ${wide} ${
                                    helpers.values.position === 'seller' ? selected : ''
                                }`}
                                type="button"
                                onClick={() => {
                                    handlePositionClick('seller', helpers);
                                }}
                            >
                                {t('akcja.gorzka.form.position.3')}
                            </button>
                        </div>
                        <ErrorMessage name="position" className={error} component="p" />
                    </div>

                    <div className={fields}>
                        <div>
                            <Field
                                className={input}
                                name="firstname"
                                placeholder={t('akcja.gorzka.form.firstname.placeholder')}
                            />
                            <ErrorMessage name="firstname" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="lastname"
                                placeholder={t('akcja.gorzka.form.lastname.placeholder')}
                            />
                            <ErrorMessage name="lastname" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                type="email"
                                className={input}
                                name="email"
                                placeholder={t('akcja.gorzka.form.email.placeholder')}
                            />
                            <ErrorMessage name="email" className={error} component="p" />
                        </div>
                        <div className={phoneInput}>
                            <Field
                                type="number"
                                className={input}
                                name="phone"
                                placeholder={t('akcja.gorzka.form.phone.placeholder')}
                            />
                            <ErrorMessage name="phone" className={error} component="p" />
                        </div>
                        <div className={codeInput}>
                            <p className={codeInfo}>{t('akcja.gorzka.form.code.label')}</p>
                            <Field
                                className={input}
                                name="code"
                                placeholder={t('akcja.gorzka.form.code.placeholder')}
                            />
                            {shopByNumberError && formRef.current?.values.code && (
                                <p className={codeError}>{t('akcja.gorzka.form.code.error')}</p>
                            )}
                        </div>
                        <div>
                            <ReactSelect
                                className={select}
                                name={'cityId'}
                                placeholder={t('akcja.gorzka.form.city.placeholder')}
                                onInputChange={popuplateCitiesOptions}
                                onChange={(newValue) => {
                                    if (formRef.current && newValue) {
                                        formRef.current.setFieldValue('cityId', newValue.value);
                                    }
                                }}
                                ref={cityRef}
                                classNamePrefix={'select'}
                                options={
                                    cities && cities.length > 0 ? getCitiesOptions(cities) : []
                                }
                                isDisabled={
                                    !!formRef.current?.values.code &&
                                    formRef.current?.values.code.toString().length > 0
                                }
                                noOptionsMessage={() => (
                                    <span>
                                        {t('akcja.gorzka.form.no.cities.1')} <br />
                                        {t('akcja.gorzka.form.no.cities.2')}
                                    </span>
                                )}
                            />
                            <ErrorMessage name="cityId" className={error} component="p" />
                        </div>
                        <div>
                            <ReactSelect
                                className={`${select} ${shopSelect}`}
                                name={'shopId'}
                                placeholder={t('akcja.gorzka.form.shop.placeholder')}
                                onChange={(newValue) => {
                                    if (formRef.current && newValue) {
                                        formRef.current.setFieldValue('shopId', newValue.value);
                                    }
                                }}
                                ref={shopRef}
                                isDisabled={
                                    selectedCityRef.current === null ||
                                    (!!formRef.current?.values.code &&
                                        formRef.current?.values.code.toString().length > 0)
                                }
                                onInputChange={populateShopsOptions}
                                classNamePrefix={'select'}
                                options={shops && shops.length > 0 ? getShopsOptions(shops) : []}
                                noOptionsMessage={() =>
                                    shops.length > 0 ? (
                                        t('akcja.gorzka.form.no.shops.3')
                                    ) : (
                                        <span>
                                            {t('akcja.gorzka.form.no.shops.1')} <br />
                                            {t('akcja.gorzka.form.no.shops.2')}
                                        </span>
                                    )
                                }
                                components={{
                                    Option: (props) => (
                                        <CustomOption {...props}>{props.children}</CustomOption>
                                    ),
                                    SingleValue: (props) => (
                                        <CustomSingleValue {...props}>
                                            {props.children}
                                        </CustomSingleValue>
                                    ),
                                }}
                            />
                            <ErrorMessage name="shopId" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="zipCode"
                                placeholder={t('akcja.gorzka.form.zipCode.placeholder')}
                                disabled={true}
                            />
                            <ErrorMessage name="zipCode" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="street"
                                placeholder={t('akcja.gorzka.form.street.placeholder')}
                                disabled={true}
                            />
                            <ErrorMessage name="street" className={error} component="p" />
                        </div>
                        <div className={centered}>
                            <Field
                                className={input}
                                name="apartmentNumber"
                                placeholder={t('akcja.gorzka.form.apartmentNumber.placeholder')}
                                disabled={true}
                            />
                            <ErrorMessage name="apartmentNumber" className={error} component="p" />
                        </div>
                    </div>
                    <div className={checkboxes}>
                        <div className={checkboxContainer}>
                            <FormCheckbox
                                textClass={checkboxLabel}
                                className={checkbox}
                                checkmarkClass={checkmark}
                                name="rules1"
                                text={t('akcja.gorzka.form.rules1')}
                                textLink={t('akcja.gorzka.form.rules1.textLink')}
                                link={staticFiles.akcjaGorzkaRegulations}
                                textContinued={t('akcja.gorzka.form.rules1.textContinued')}
                            />
                            <ErrorMessage name="rules1" className={error} component="p" />
                        </div>
                        <div className={checkboxContainer}>
                            <FormCheckbox
                                textClass={checkboxLabel}
                                className={checkbox}
                                checkmarkClass={checkmark}
                                name="rules2"
                                text={t('akcja.gorzka.form.rules2')}
                            />
                            <ErrorMessage name="rules2" className={error} component="p" />
                        </div>
                    </div>
                    <Button
                        isLoading={isLoading}
                        type="button"
                        className={button}
                        onClick={() => {
                            handleSubmit(helpers.values, helpers);
                        }}
                    >
                        {t('akcja.gorzka.form.submit')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

const CustomOption = (props: any) => {
    const { label } = props;
    return (
        <components.Option {...props}>
            <Markdown>{label}</Markdown>
        </components.Option>
    );
};

const CustomSingleValue = (props: any) => {
    return (
        <components.SingleValue {...props}>
            <Markdown>{props.children}</Markdown>
        </components.SingleValue>
    );
};

function getCitiesOptions(cities: { cityId: number; name: string }[]) {
    return cities.map((city) => {
        return { label: city.name, value: city.cityId };
    });
}

function getShopsOptions(
    shops: { cityId: number; name: string; shopId: number; street: string; houseNumber: string }[]
) {
    return shops.map((shop) => {
        return {
            label: `${shop.name} <span>${shop.street} ${shop.houseNumber}</span>`,
            value: shop.shopId,
        };
    });
}

export default AkcjaGorzkaForm;
