// extracted by mini-css-extract-plugin
export var backgroundAnimation = "akcja-gorzka-form-module--backgroundAnimation--3e921";
export var big = "akcja-gorzka-form-module--big--27fd6";
export var button = "akcja-gorzka-form-module--button--7cd97";
export var buttonRadio = "akcja-gorzka-form-module--button-radio--5fa35";
export var buttons = "akcja-gorzka-form-module--buttons--7c83a";
export var centered = "akcja-gorzka-form-module--centered--f17a5";
export var checkbox = "akcja-gorzka-form-module--checkbox--6bb34";
export var checkboxContainer = "akcja-gorzka-form-module--checkbox-container--e6033";
export var checkboxLabel = "akcja-gorzka-form-module--checkbox-label--0f0c0";
export var checkboxes = "akcja-gorzka-form-module--checkboxes--932d9";
export var checkmark = "akcja-gorzka-form-module--checkmark--46b2c";
export var codeError = "akcja-gorzka-form-module--code-error--03c20";
export var codeInfo = "akcja-gorzka-form-module--code-info--3588c";
export var codeInput = "akcja-gorzka-form-module--code-input--fc561";
export var container = "akcja-gorzka-form-module--container--d7ec8";
export var counter = "akcja-gorzka-form-module--counter--3c52d";
export var divider = "akcja-gorzka-form-module--divider--4f4ae";
export var error = "akcja-gorzka-form-module--error--1df87";
export var errorBlink = "akcja-gorzka-form-module--error-blink--b22c1";
export var fields = "akcja-gorzka-form-module--fields--1534c";
export var fileContainer = "akcja-gorzka-form-module--file-container--207f0";
export var fileInput = "akcja-gorzka-form-module--file-input--c2dee";
export var hiddenInfo = "akcja-gorzka-form-module--hidden-info--6fd91";
export var hiddenInputs = "akcja-gorzka-form-module--hidden-inputs--34129";
export var hint = "akcja-gorzka-form-module--hint--6d4b8";
export var info = "akcja-gorzka-form-module--info--4bf79";
export var infoItem = "akcja-gorzka-form-module--info-item--488ba";
export var input = "akcja-gorzka-form-module--input--69763";
export var loading = "akcja-gorzka-form-module--loading--52dff";
export var noMargin = "akcja-gorzka-form-module--no-margin--b40ce";
export var optional = "akcja-gorzka-form-module--optional--fe7e1";
export var phoneInput = "akcja-gorzka-form-module--phone-input--479b0";
export var position = "akcja-gorzka-form-module--position--e1292";
export var question = "akcja-gorzka-form-module--question--8a9a9";
export var questionBox = "akcja-gorzka-form-module--question-box--40a96";
export var select = "akcja-gorzka-form-module--select--3107c";
export var selected = "akcja-gorzka-form-module--selected--294cb";
export var shopSelect = "akcja-gorzka-form-module--shop-select--cf225";
export var textarea = "akcja-gorzka-form-module--textarea--1bff0";
export var title = "akcja-gorzka-form-module--title--fbfb1";
export var visible = "akcja-gorzka-form-module--visible--b773b";
export var wide = "akcja-gorzka-form-module--wide--8a438";